import request from '@/api/request.js'

// 获取团队系统设置
export function setData (data) {
  return request({
    url: '/api/settings/setRetailerSettings',
    method: 'POST',
    data
  })
}
// 获取设置信息
export function getData (data) {
  return request({
    url: '/api/settings/getRetailerSettings',
    method: 'POST',
    data
  })
}

export function getSupplierSetting (data) {
  return request({
    url: '/api/settings/getSupplierSettings',
    method: 'POST',
    data
  })
}

export function setSupplierSetting (data) {
  return request({
    url: '/api/settings/setSupplierSettings',
    method: 'POST',
    data
  })
}

export function getQrCode (data) {
  return request({
    url: '/api/wx/mp/qr',
    method: 'POST',
    data
  })
}

//查询订阅列表
export function getSubscription (data) {
  return request({
    url: '/api/subscription/list',
    method: 'POST',
    data
  })
}

//添加订阅人
export function addSubscription (data) {
  return request({
    url: '/api/subscription/add',
    method: 'POST',
    data
  })
}
//删除订阅人
export function delSubscription (data) {
  return request({
    url: '/api/subscription/remove',
    method: 'POST',
    data
  })
}

// 获取授权码
export function getAuthCode (data) {
  return request({
    url: '/api/auth/getCode',
    method: 'POST',
    data
  })
}

