<template>
  <div>
  <el-dialog title="展示所有的消息以及消息订阅人" :visible.sync="classflag" :before-close="handleClose" width="980px">
       <el-table
        :data="tableData"
        border
        ref="tableRef"
         @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">

        <el-table-column
          type="selection"
          width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="消息类型"
          width="120">
        </el-table-column>
         <el-table-column
          prop="description"
          label="具体描述"
          >
        </el-table-column>      
        <el-table-column
          prop="category"
          label="分类"
          width="90" header-align="center" align="center">
        </el-table-column>
        
         <el-table-column
          prop="remark"
          label="消息接收人">
           <template slot-scope="scope">
            <el-tag
                v-for="tag in scope.row.subscribeUser"
                :key="tag.id"
                closable
                @close="handleDel(tag, scope.row.messageType)"
                style="margin-right: 5px"
                >
                {{tag.nickName}}
              </el-tag>
           </template>
        </el-table-column>

        <el-table-column
          label="操作" width="80"  header-align="center" align="center">
           <template slot-scope="scope">
             <el-button @click="addcontact(scope.row)" type="text" size="small">修改</el-button>
           </template>
        </el-table-column>
      </el-table>
       <div style="padding: 10px 0">
       <el-button @click="addFun" type="primary">添加消息接收人</el-button>
       <el-button @click="removeFun" type="info" style="margin-left: 10px">移除消息接收人</el-button>
       </div>
  </el-dialog>



  <el-dialog title="修改消息订阅人" :visible.sync="contactFlag" :before-close="contactClose" width="680px">
    <!-- <el-alert
    title="温馨提示：系统将自动发送验证信息到所填手机号和邮箱，通过验证之后方可接受消息"
    type="error">
  </el-alert> -->
   <div class="msgcss" v-if="rowmsg.name">正在设置的消息类型：【{{rowmsg.name}}】 - 【{{rowmsg.description}}】</div>
   <div v-else class="msgcss">
     批量设置的消息类型：
     <p v-for="item in multipleSelection" :key="item.messageType">【{{item.name}}】- 【{{item.description}}】</p>
   </div>
      <el-form ref="formAdd" :model="formEmp" label-position="right" label-width="120px">
         <el-form-item label="选择消息订阅人">
           <el-select
                v-model="formEmp.employeeId"
                placeholder="请选择联系人,可多选"
                filterable
                multiple
                clearable
                style="width: 400px"
              >
                <el-option
                  v-for="item in employeeData"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id"
                  :disabled="item.status!=0"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <div class="midCss">
          <el-button @click="contactClose" size="medium">取 消</el-button>
          <el-button type="primary" :loading="logining" @click="addEmployees" size="medium">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { addSubscription, getSubscription, delSubscription } from "@/api/system";
import { getUserList } from "@/api/user";
export default {
  name: 'msgLayer',
  props: ['isShow'],
  data () {
    return {
      formEmp: {
        employeeId: []
      },
      tableData: [],
      contactData: [],
      employeeData: [],
      multipleSelection: [],
      rowmsg: {
        name: '',
        description: ''
      },
      logining: false,
      classflag: false,
      contactFlag: false,
      msgflag: false,
    }
  },
  watch: {
    isShow: {
      deep: true,
      handler (newVal, oldVal) {
        console.log(oldVal)
        this.classflag = newVal
      }
    }
  },
  mounted() {
    this.getSubscriptionList()
  },
  methods: {
    handleClose(){
      this.classflag = false
      if(this.$refs.tableRef){
        this.$refs.tableRef.clearSelection()
      }
      this.$emit('cancalfunc', false)
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    getUser(row){
      var that = this
      getUserList({}).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.employeeData = res.data
          if(row && row.messageType){
            var curAdmin = []
            row.subscribeUser.map((items)=>{
              curAdmin.push(items.id)
            })
            that.formEmp.employeeId = curAdmin;
          }
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleDel(rows, messageType){
      var that = this
      var muArr = []
      if(that.multipleSelection.length > 0){
        that.multipleSelection.map((item) => {
          muArr.push(item.messageType)
        })
      }
     const objs = {
        "messageTypes": that.multipleSelection.length==0?[messageType]:muArr,
        "users": that.multipleSelection.length==0?[rows]:rows
      }
      delSubscription(objs).then(res => {
        that.$message({
          message: '删除成功',
          type: 'success'
        });
        that.getSubscriptionList();
      })

    },
    getSubscriptionList(){
      var that = this
      const param = {
        "role": window.sessionStorage.getItem("role")
      }
      getSubscription(param).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data
        }else{
          this.$message.error(res.message)
        }
      })
    },

    addFun(){
      var that = this
      if(that.multipleSelection.length==0){
        that.$message.error('请选择订阅消息类型')
        return
      }else{
        that.formEmp.employeeId = []
        that.contactFlag = true
        that.getUser()
      }
    },
  
    removeFun(){
      var that = this
      if(that.multipleSelection.length>0){
        console.log(that.multipleSelection)
        
        that.$confirm('确定要全部移除吗？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          that.handleDel(that.multipleSelection[0].subscribeUser, null)
        })
       
      }else{
        that.$message.error('请选择需要移除的订阅消息类型')
      }

    },
    addcontact(row){
      this.contactFlag = true
      console.log(row)
      this.formEmp.employeeId = []
      if(this.$refs.tableRef){
        this.$refs.tableRef.clearSelection()
      }
      this.rowmsg = row
      this.getUser(row)
    },
    contactClose(){
      this.contactFlag = false
      if(this.$refs.tableRef){
        this.$refs.tableRef.clearSelection()
      }
    },
    addEmployees(){
      var that = this
      var userArr = []
      var muArr= []
      console.log(that.formEmp.employeeId)
      if(that.formEmp.employeeId.length > 0){
        that.employeeData.map((item)=>{
          that.formEmp.employeeId.map((its)=>{
            var objs = {}
            if(item.id == its){
              objs.id = its;
              objs.nickName = item.nickName
              userArr.push(objs)
            }
          })
        })
        console.log(userArr)

      }else{
        that.$message.error('请选择联系人')
      }
      if(that.multipleSelection.length > 0){
        that.multipleSelection.map((item) => {
          muArr.push(item.messageType)
        })
      }
      const param = {
        "messageTypes": that.multipleSelection.length==0?[that.rowmsg.messageType]:muArr,
        "users": userArr
      }
      addSubscription(param).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.$message({
              message: '新增成功',
              type: 'success'
            });
          that.contactFlag = false
          that.getSubscriptionList();
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.el-dialog__body{
  padding: 10px 20px;
}
.midCss {
  text-align: right;
  display: block;
  padding-top: 20px;
}
.msgcss {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  line-height:44px;
  margin-bottom: 10px;
}
.msgcss p{
  line-height:22px;
  font-size: 12px;
}
</style>
