<template>
  <div>

     <div class="footerBar">
       <div class="rightBtn">
         <el-button type="primary" size="medium" @click="submitFun('goodArr')">确认提交</el-button>
         <el-button type="info" size="medium" @click="cancalFun" plain>取消</el-button>
       </div>
     </div>
     <div class="contents">
       <div class="mainbox">
    <!-- 
        <el-tabs>
            <el-tab-pane label="团长设置" name="first"></el-tab-pane>
        </el-tabs> 
    -->
  <div class="formTable">
        <div class="title">订单设置：</div>
        <el-form ref="goodArr" :model="goodArr" label-position="right" label-width="200px">
 
          <el-form-item label="订单是否自动匹配供应商：">
            <el-radio-group v-model="goodArr.autoMatchSupplier">
             <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="匹配完供应商后立即交单：">
            <el-radio-group v-model="goodArr.autoSendAfterMatchedSupplier">
             <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="定时自动交单给供应商：">
            <el-radio-group v-model="goodArr.autoSendSupplier">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
           <el-form-item label="定时自动交单时间：" v-show="goodArr.autoSendSupplier">
            <div class="timebox">
            <div class="additem" @click="addfun">+</div>
            <div v-for="(item, index) in timeArr" :key="index" style="margin-bottom:10px"> 
            <el-time-picker
                v-model="item.value"
                placeholder="任意时间点"
                format="HH:mm"
              >
            </el-time-picker>
            <div class="delitem" v-if="index>0" @click="delfun(index)">×</div>
            </div>
            </div>
          </el-form-item>

          <div class="title">商品设置：</div>

          <el-form-item label="启用商品品牌：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsBrand">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品产地：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsPlace">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品参数：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsProperties">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用自定义分类：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsCategory">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- <el-form-item label="启用虚拟商品：">
            <el-radio-group v-model="goodArr.goodsSetting.enableGoodsVirtual">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="启用商品简称：">
            <el-radio-group v-model="goodArr.goodsSetting.enableShortName">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品卖点：">
            <el-radio-group v-model="goodArr.goodsSetting.enableRecommend">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品描述：">
            <el-radio-group v-model="goodArr.goodsSetting.enableDescription">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品网盘资料：">
            <el-radio-group v-model="goodArr.goodsSetting.enablePan">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品详情图册：">
            <el-radio-group v-model="goodArr.goodsSetting.enableDetail">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用商品售后政策：">
            <el-radio-group v-model="goodArr.goodsSetting.enableAfterSale">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="启用物流属性：">
            <el-radio-group v-model="goodArr.goodsSetting.enableDeliveryExtend">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

        </el-form>

        <div class="title">消息设置：</div>
        
        <div class="mention">
            <span class="label">关注公众号，接收订单消息：（二维码请勿分享他人）
            <el-button type="primary" @click="dingyueBtn" style="display: block">订阅消息设置</el-button> 
            </span>           
            <span class="qrcode" ><img :src="qrCode" /></span>      
        </div>        


       </div>
       </div>   
    </div>
     <msglayer :isShow="isCollapse" @cancalfunc="cancalFuns"></msglayer>
  </div>
</template>

<script>
import msglayer from "@/components/msg";
import { getData, setData, getQrCode } from "@/api/system";
import backImg from "@/assets/logofang.png";

let moment = require('moment');
export default {
  name: 'retailDetail',
   components: { msglayer },
  data() {
    return {
      goodArr: {
        autoMatchSupplier: 1,
        autoSendMoments: [],
        autoSendSupplier: 0,
        autoSendAfterMatchedSupplier: 1,
        goodsSetting: {
        },
      },
      setid: '',
      timeArr: [],
      isCollapse: false,
      role: window.sessionStorage.getItem("role"),
      qrCode: backImg,
    }
  },
  created: function () {
   
  },
  methods:{
    addfun(){
      var that = this
      var objs= {
        value: ''
      }
      this.timeArr.splice(0, 0, objs)
    },
    delfun(n){
      var that = this
      that.timeArr.splice(n,1)
    },
    getSetData(){
      var that = this
      var datas = {}
      getData(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.setid = res.data.id
          that.goodArr = res.data.retailerSetting
          that.goodArr.autoSendMoments.map((iy)=>{
            console.log(iy)
            var objs={}
            objs.value = new Date('1970-01-01 ' + iy)
            that.timeArr.push(objs);
            console.log(that.timeArr)
          })
       
        }else{
          this.$message.error(res.message)
        }
      })
    }, 
    submitFun(){
      var that = this
      var params = {}
      params.id = that.setid
      that.goodArr.autoSendMoments =[]
      //that.goodArr.autoSendMoments = moment(that.goodArr.autoSendMoments).format('HH:mm')
      that.timeArr.map((items)=>{
        items.value = moment(items.value).format('HH:mm')
        that.goodArr.autoSendMoments.push(items.value)
      })
      params.setting = that.goodArr
      setData(params).then(res => {
        if(res.code === 200){
          that.$message({
            message: '设置成功',
            type: 'success'
          })
          this.$router.push({
            path: "/system"
          })
        }else{
          that.$message.error(res.message)
        }
        that.loading = false
      })
    },
    cancalFun(){
      this.$router.go(-1)
    },
    dingyueBtn(){
      this.isCollapse = true
    },
    cancalFuns(e){
      console.log(e)
      this.isCollapse = false
    },
    showQrCode() {
      getQrCode({}).then(res => {
        if(res.code === 200) {
          this.qrCode = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
  },
  mounted() {
   this.getSetData()
   this.showQrCode()
  }
}
</script>

<style scoped>
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.timebox{
  width: 300px;
  display: block;
}
.additem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07C160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}
.title {
  background-color: #f5f6f8;
  line-height: 40px;
  margin:0px 0px 10px 0px;
  font-size:15px;
  padding-left:20px;
  font-weight: bold;
}
.label {
  margin-left: 80px;
  font-size:14px;
  color: #606266;
  float:left;
  padding:  5px 12px 0 0;
  line-height:40px;
}
.qrcode img {
  width:100px;
  height:100px;
}
</style>