import request from '@/api/request.js'

// 获取团队接口
export function getUserList (data) {
  return request({
    url: '/api/group/user/list',
    method: 'POST',
    data
  })
}
// 新增接口
export function addUser (data) {
  return request({
    url: '/api/group/user/add',
    method: 'POST',
    data
  })
}
// 删除接口
export function delUser (data) {
  return request({
    url: '/api/group/user/delete',
    method: 'POST',
    data
  })
}
// 编辑接口
export function editUser (data) {
  return request({
    url: '/api/group/user/edit',
    method: 'POST',
    data
  })
}
// 新增接口
export function editTeams (data) {
  return request({
    url: '/api/group/changeGroupName',
    method: 'POST',
    data
  })
}
// 编辑接口
export function chakanTeams (data) {
  return request({
    url: '/api/group/getGroup',
    method: 'POST',
    data
  })
}

// 发送打款验证码
export function sendRemitPasswordCode(data) {
  return request({
    url: '/api/user/sendRemitPasswordVerifyCode',
    method: 'POST',
    data
  })
}

// 设置打款密码
export function setRemitPassword(data) {
  return request({
    url: '/api/user/setRemitPassword',
    method: 'POST',
    data
  })
}

// 展示部门树
export function organizationTree(data) {
  return request({
    url: '/api/organization/department/tree',
    method: 'POST',
    data
  })
}
// 获取员工信息
export function organizationEmployee(data) {
  return request({
    url: '/api/organization/department/employee',
    method: 'POST',
    data
  })
}

// 删除员工信息
export function removeEmployee(data) {
  return request({
    url: '/api/organization/department/removeEmployee',
    method: 'POST',
    data
  })
}
// 删除部门
export function removeTree(data) {
  return request({
    url: '/api/organization/department/remove',
    method: 'POST',
    data
  })
}
// 查询部门员工
export function getTreeMember(data) {
  return request({
    url: '/api/organization/department/member',
    method: 'POST',
    data
  })
}


// 创建员工信息
export function addEmployee(data) {
  return request({
    url: '/api/organization/department/addEmployee',
    method: 'POST',
    data
  })
}

// 新增部门
export function addOrg(data) {
  return request({
    url: '/api/organization/department/add',
    method: 'POST',
    data
  })
}

// 编辑部门
export function editOrg(data) {
  return request({
    url: '/api/organization/department/edit',
    method: 'POST',
    data
  })
}

// 角色列表
export function getRole(data) {
  return request({
    url: '/api/group/listRoles',
    method: 'POST',
    data
  })
}
// 新增角色
export function addRole(data) {
  return request({
    url: '/api/group/addRole',
    method: 'POST',
    data
  })
}
// 编辑角色
export function editRole(data) {
  return request({
    url: '/api/group/editRole',
    method: 'POST',
    data
  })
}
// 获取权限列表
export function getRolePermissions(data) {
  return request({
    url: '/api/group/listPermissions',
    method: 'POST',
    data
  })
}

// 删除角色
export function delRole(data) {
  return request({
    url: '/api/group/deleteRole',
    method: 'POST',
    data
  })
}
// 修改用户角色
export function changeUserRole(data) {
  return request({
    url: '/api/group/changeUserRole',
    method: 'POST',
    data
  })
}
